.form-radio-grp-items {
    display: flex;
    flex-direction: row;

    .form-radio-grp-label {
        min-width: 250px;
    }

    .form-txt-radio-grp-label {
        min-width: 250px;
    }

    .form-txt-radio-grp-label,
    .form-radio-grp-label {
        padding-top: 10px;
        font-family: 'WorkSans-SemiBold';
        font-size: 16px;
    }

    .form-radio-grp {
        gap: 5px;
    }

    .form-txt-radio-grp {
        gap: 2px;
    }

    .form-txt-radio-grp,
    .form-radio-grp {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-left: 5px;
        margin-right: 100px;
        width: 100%;

        .radio-btn-copy-container,
        .radio-btn-row-container {
            display: flex;
            flex-direction: row;

            img {
                margin-top: 4px;
            }

            .img-radio-txt {
                margin-top: 3px;
                color: @txt-color-5;
                width: 100%;
                height: 20px;
                font-size: 11px;
                font-family: 'WorkSans-Medium';
                text-align: center;
                margin-left: 10px;
                margin-top: 10px;
                margin-right: 10px;
            }
        }

        .radio-btn-container {
            display: flex;
            flex-direction: column;
            z-index: 0;

            .radio-icon {
                margin: auto;
            }

            .img-radio-txt {
                color: @txt-color-5;
                width: 100%;
                height: 20px;
                font-size: 11px;
                font-family: 'WorkSans-Medium';
                word-wrap: break-word;
                text-align: center;
                margin-top: 2px;
            }
        }

        .copyTxtRadio {
            margin-top: 5px;
            margin-right: 10px;
            background-color: @background-color-9;
            padding: 2px 20px !important;
            font-family: 'WorkSans-Medium' !important;
            font-size: 15px !important;
            border-color: @background-color-8;
            min-height: 35px;

            color: @txt-color-1;
            text-align: center;
            border-radius: 5px;
            min-width: 70px;

            .radio-btn-row-txt {
                margin-right: 20px;
            }
        }

        .numRadio {
            background-color: @btn-bg-color-3;
            font-size: 16px;
            font-family: 'WorkSans-Regular';
            padding: 7px 35px;
            min-height: 45px;
        }

        .numRadio {
            color: @txt-color-1;
            text-align: center;
            border-radius: 5px;
            min-width: 70px;

            .ant-radio-button-checked {
                background-color: @background-color-8;
                border-color: @border-color-8  !important;
            }
        }

        .copyTxtRadio:hover,
        .numRadio:hover {
            background-color: @background-color-8;
            border-color: @border-color-8;
        }

        .imgRadioRow,
        .imgRadio {
            border-radius: 5px;
            min-height: 50px;
            padding: 2px 5px;
            border: 1px solid #d9d9d9;
            filter: gray;
            -webkit-filter: grayscale(100%);
            z-index: 0;
        }

        .ant-radio-button-wrapper-checked {
            border-color: @btn-bg-color-1;
            filter: none;
            -webkit-filter: grayscale(0%);

            .img-radio-txt {
                color: @txt-color-4;
            }
        }

        .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
            color: @txt-color-1  !important;
        }

        .txtRadio {
            border: 1px solid @border-color-4;
            color: @txt-color-9;
            font-family: 'WorkSans-SemiBold';
            font-size: 15px;
            border-radius: 5px;
            padding: 0 10px !important;
            transition: 0.3s;
            width: auto;

            .ant-radio-button-checked {
                background-color: @btn-bg-color-1;
                border-color: @btn-bg-color-1;
                color: @txt-color-1  !important;
                border: 1px solid @btn-bg-color-1;
            }
        }

        .txtRadio:hover {
            background-color: @btn-bg-color-1;
            border-color: @btn-bg-color-1;
            color: @txt-color-1;
        }

        .ant-radio-button-wrapper:not(:first-child)::before {
            background-color: transparent !important;
        }
    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;