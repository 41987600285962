.register-container {
  width: 100%;
  min-width: @max-width;
  padding-top: 8%;
  padding-bottom: 8%;
  background: url(../../assets/images/innerpage-register-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: scroll;

  .register-wrapper {
    width: 980px;
    max-width: @max-width;
    margin: auto;

    .register-form-container {
      border: 2px solid @input-border-color-focus;
      border-radius: 15px;
      padding: 2.5rem;
      background-color: @background-color;

      .register-form-left-element {
        margin: 0 auto;

        .reg-img {
          width: 90%;
        }
      }

      .register-form-right-element {
        display: flex;
        flex-direction: column;

        .ant-steps {
          padding: 0px 50px 50px 50px;

          .ant-steps-item-title::after {
            background-color: @steps-inactive-background;
          }

          .ant-steps-item-wait .ant-steps-item-icon {
            background-color: @steps-inactive-background;
            border-color: @steps-inactive-background;

            .ant-steps-icon {
              color: @txt-color-1;
            }

          }

          .ant-steps-item-active .ant-steps-item-icon {
            background-color: @steps-active-background;
          }
        }

        .register-form-items {

          .register-form-title {
            color: @component-title-color;
            font-family: 'WorkSans-Bold';
            font-size: 24px;
            padding-bottom: 1.5rem;
          }

          .register-form-item {
            display: flex;
            flex-direction: row;
            justify-content: start;
            align-items: flex-start;

            .globe-icon {
              margin-top: 30px;
              font-size: 18px;
            }

            .auth-country-selector {
              border: none;
              margin-top: 10px;
              
              .ant-select-selector {
                border: none;
              }
            }

            .form-username-icon,
            .form-password-icon,
            .form-aff-icon {
              width: 16px;
              margin-top: 24px;
            }

            .form-email-icon {
              width: 16px;
              margin-top: 30px;
            }

            .form-calendar-icon  {
              width: 16px;
              margin-top: 38px;
            }

            .ant-form-item {
              width: 100%;
              padding-left: 10px;
              margin-bottom: 0px;
            }

            .float-label {
              position: relative;
              padding-top: 12px;
              margin-bottom: 12px;
              overflow: hidden;
            }

            .label {
              font-family: 'WorkSans-Medium';
              font-size: 15px;
              font-weight: normal;
              position: absolute;
              pointer-events: none;
              left: 0px;
              bottom: 4px;
              transition: 0.4s ease all;
              color: @input-label-color;
            }

            .label-float {
              top: 0;
              // left: -100%;
              transform: translateY(1);
              transition: top 0.6s ease;
            }

            .otp-request-btn {
              min-height: 35px;
              background-color: @background-color-5;
              padding: 0px 15px;
              border-radius: 100px;
              font-size: 14px;
              color: @txt-color-1;
              font-family: 'WorkSans-SemiBold';
              margin-top: 15px;
          }
          
            .input {
              position: relative;
              border-top: none;
              border-left: none;
              border-right: none;
              border-bottom: 1px solid @input-border-color;
              background-color: transparent;
              box-shadow: none;
              // transition: 0.5s;
              width: 100%;
              margin-top: 15px;
              padding: 0px;
              z-index: 0;
            }

            .ant-input-password {
              margin-top: 10px;
            }

            .input-underline {
              position: absolute;
              left: -100%;
              transform: translateX(100%);
              transform-origin: -100px;
              transition: transform 0.3s linear;
              border-bottom: 2px solid @primary-color;
              overflow: hidden;
              height: 2px;
              width: 100%;
              bottom: 0;
            }

            .ant-input-group {
              z-index: 0;
              margin-top: 12px;

              .ant-select-selector {
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid @input-border-color;
              }

              .ant-input {
                position: relative;
                background-color: transparent;
                border-top: none;
                border-left: none;
                border-right: none;
                border-bottom: 1px solid @input-border-color;

                &:focus {
                  box-shadow: unset;
                }

                &:hover {
                  z-index: 0;
                }
              }
            }

            .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
              box-shadow: none;
            }

            .float-label.ant-picker {
              width: 100%;
            }

            .ant-picker-input input::placeholder {
              color: transparent;
            }

            .register-name-note {
              padding-left: 25px;
              font-size: 11px;
              padding-bottom: 25px;
            }
          }
        }

        .register-tnc {
          font-family: 'WorkSans-Regular';
          font-size: 14px;
          margin-top: 20px;

          .tnc-bold {
            font-family: 'WorkSans-Bold';
          }
        }

        .ant-form-item-control {
          overflow: hidden;
          margin-bottom: 4px;
        }

        .form-button .ant-form-item-control-input-content {
          display: flex;
          justify-content: center !important;
          align-items: center !important;
        }

        .register-next-btn,
        .register-deposit-btn {
          width: unset;
          margin: 20px auto;
          text-align: center;
          background-color: @btn-bg-color-1;
          color: @button-text;
          font-family: 'WorkSans-SemiBold';
          border-radius: 50px;
          font-size: 15px;
        }

        .phone-label {
          left: 8px !important;
          margin-left: 100px;
        }

        .dob-float-label, .dob-input {
          width: 100%;
        }
      }
    }
  }
}

.country-dropdown {
  min-width: 300px !important;

  .country-dropdown-item {
      >div {
          display: flex;
          align-items: center;
      }

      img {
          width: 24px;
          margin-right: 8px;
      }
  }
}

.country-selected-title {
  display: flex;
  align-items: center;

  img {
      width: 24px;
      margin-right: 8px;
  }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;