.dashboard-container {
    width: 100%;
    background-color: @background-color-7;
    position: relative;

    .header-logo {
        min-height: 50px;
        align-self: center;
    }
    
    .dashboard-tab-name-container {
        display: flex;
        flex: row;
        justify-content: flex-start;
        align-items: center;
        padding: 15px 10px;
        border-radius: 15px;

        .home-icon {
            -webkit-mask: url(../../assets/icons/icon-home.svg)no-repeat center/contain;
        }

        .member-icon {
            -webkit-mask: url(../../assets/icons/icon-member.svg)no-repeat center/contain;
        }

        .withdrawal-icon {
            -webkit-mask: url(../../assets/icons/icon-withdrawal.svg)no-repeat center/contain;
        }

        .winloss-icon {
            -webkit-mask: url(../../assets/icons/icon-win-loss.svg)no-repeat center/contain;
        }

        .report-icon {
            -webkit-mask: url(../../assets/icons/icon-report.svg)no-repeat center/contain;
        }

        .profile-icon {
            -webkit-mask: url(../../assets/icons/icon-profile.svg)no-repeat center/contain;
        }

        .profile-icon,
        .report-icon,
        .winloss-icon,
        .withdrawal-icon,
        .member-icon,
        .home-icon {
            height: 25px;
            width: 25px;
            margin-right: 20px;
            background-color: @txt-color-2;
            transition: 0.6s;
        }
    }

    .ant-tabs-nav {
        width: 20%;
        min-height: 100vh;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
        background-color: @background-color !important;
    }

    .ant-tabs-nav-list {
        border-right: 1px solid @border-color-3;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
    }

    .ant-tabs-content-holder {
        border-left: none;
    }

    .ant-tabs-tab:nth-child(8) {
        position: absolute;
        bottom: 0px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        flex-wrap: wrap;
        cursor: default !important;
        padding-bottom: 20px !important;
        padding-top: 20px !important;

        .ant-tabs-tab-btn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            white-space:normal;
            font-size: 11px;
            max-width: 80%;
            color: @border-color-3;
        }
    }

    .ant-tabs-tab-disabled:nth-child(1) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-content: center;
        cursor: default !important;
        border-bottom: 1px solid  @border-color-3;
        padding-bottom: 20px !important;
        padding-top: 10px !important;
        max-height: 200px;
    }

    .ant-tabs-tab {
        margin-top: 0px !important;
        padding: 8px 8px 0px 8px !important;
        //padding-right: 40px !important;
        transition: 0.3s;

        @media only screen and (max-width: 820px) {
            padding-right: 20px !important;
        }

        .ant-tabs-tab-btn {
            border-radius: 10px;
            font-family: 'WorkSans-SemiBold';
            font-size: 14px;

            svg {
                color: @border-color-8
            }
        }

        &:hover {
            color: @background-color-8;
        }
    }

    .ant-tabs-tab-active {
        transition: 0.3s;
        
        .ant-tabs-tab-btn {
            background-color: @btn-bg-color-1;
            color: @txt-color-1;
            width: 100%;

            .profile-icon,
            .report-icon,
            .winloss-icon,
            .withdrawal-icon,
            .member-icon,
            .home-icon {
                background-color: @txt-color-1;
            }
        }
    }

    .ant-tabs-nav-more {
        display: none;
    }

    .ant-tabs-ink-bar {
        display: none;
    }

    .bottom-referral-container {
        width: 17%;
        position: absolute;
        top: 380px;
        left: 25px;
        border-radius: 10px;
        padding-bottom: 20px;

        .bottom-referral {
            position: relative;

            .bottom-referrals-txt-2 {
                position: absolute;
                padding: 0px 20px;
                bottom: 75px;
                color: @txt-color-1;
            }

            .bottom-referral-share-container {
                position: absolute;
                bottom: 20px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
                gap: 10px;
                background-color: @background-color-12;
                width: 80%;
                left: 15px;
                border-radius: 100px;

                .dashboard-referrals-share-code {
                    font-weight: bold;
                    padding: 5px 20px;
                }

                .ref-share-btn {
                    background-color: @background-color;
                    padding: 5px 10px;
                    border-top-right-radius: 100px;
                    border-bottom-right-radius: 100px;
                    font-size: 18px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
        img {
            max-width: 200px;
            border-radius: 10px;
        }
    }

    .ant-tabs-content {
        width: 75%;
        position: absolute;
        top: 100px;
        height: auto;
        max-height: 300px;
    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;