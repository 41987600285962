.header-right-first-row {
    position: absolute;
    top: 10px;
    right: 0px;
    flex: 2;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    gap: 15px;
    background-color: @background-color-7;
    padding: 15px;

    .header-welcome {
        font-family: 'WorkSans-Medium';
        font-size: 16px;
        color: @txt-color-9
    }

    .home-menu-container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        @media only screen and (max-width: 900px) {
            display: none;
        }

        .home-menu-items {
            font-family: 'WorkSans-Medium';
            font-size: 14px;
            cursor: pointer;
            transition: 0.5s;
        }

        .home-menu-items:hover {
            color: @txt-color-10
        }
    }

    .ant-row {
        input {
            max-width: 140px !important;
            padding: 5px 8px !important;
        }
    }

    .login-register-wrapper {
        display: flex;
        gap: 4px;
    }

    .login-header-elements {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .user-container:hover {
            background-color: @background-color-9;

            .login-user-icon {
                .profile-icon {
                    background-color: @background-color;
                }
            }
        }

        .user-container {
            position: relative;
            display: flex;
            flex-direction: row;
            gap: 10px;
            padding: 4px 12px;
            z-index: 1;
            color: @txt-color-4;
            cursor: pointer;
            border-radius: 10px;
            background-color: @background-color;
            transition: 0.5s;

            .login-user-icon {
                margin-top: 5px;

                svg {
                    color: @border-color-8;
                }
            }

            .login-username {
                font-family: 'WorkSans-Medium';
                font-size: 15px;
                font-style: normal;
                padding-top: 4px;
                cursor: pointer;
            }

        }

        .login-inbox-btn {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 100px;
            background-color: @background-color-5;
            color: @txt-color-1;
            padding: 4px 8px;
            cursor: pointer;
            transition: all 0.6s ease-in-out;

            &:hover {
                background-color: @background-color-8;

            }

            svg {
                font-size: 19px;
            }
        }

        .login-wallet-btn {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            border-radius: 10px;
            background-color: @background-color-9;
            gap: 10px;
            color: @txt-color-1;
            //border: 1px solid @border-color-7;
            padding: 0px 20px 0px 4px;
            font-family: 'WorkSans-SemiBold';
            font-size: 15px;
            cursor: pointer;

            .login-wallet-btn-amt {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                gap: 10px;
                padding-top: 2px;

                .login-wallet-label {
                    font-family: 'WorkSans-Regular' !important;
                }
            }

            .wallet-icon-container {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-content: center;
                padding: 6px;
                background-color: @btn-bg-color-1;
                border-radius: 5px;
            }

            svg {
                font-size: 17px;
            }

        }

        .ant-btn {
            border: 1px solid @background-color
        }

        .logout-btn {
            //border: 1px solid @border-color-7;
            color: @txt-color-9;
            border-radius: 10px;
            font-size: 15px;
            min-height: 36px;
            padding: 6px 15px 7px 15px;
            font-family: 'WorkSans-SemiBold';
            cursor: pointer;
            transition: 0.5s;

            svg {
                color: @border-color-8
            }
        }

        .logout-btn:hover {
            background-color: @background-color-9;
            border-color: @background-color-9;

            .signout-icon {
                background-color: @background-color
            }
        }
    }

    .header-mobile-icon {
        max-height: 45px;
    }

    .header-forgot-password {
        font-size: 13px;
        color: @input-border-color-focus;
        width: 100%;
        text-align: center;
        cursor: pointer;
        font-family: 'WorkSans-Regular';
        margin-top: 3px;
    }

    .header-language-img {
        width: 32px;
        height: 32px;
    }

    .ant-form-item {
        margin-bottom: 0px;
        text-transform: uppercase;
        color: #ffffff;
        font-size: 13px;
        font-family: 'WorkSans-Regular';

        .ant-input {
            border-radius: 100px;
            padding: 4px 8px;
            width: 220px;
        }

        .ant-input:focus {
            border-color: @primary-color;
        }

        .ant-input-password {
            border-radius: 100px;
            padding: 0;

            .ant-input-suffix {
                display: none;
            }
        }

        .login-btn {
            border-radius: 100px;
            padding: 0px 20px;
            background-color: @btn-bg-color-1;
            font-weight: bold;
            border: none;
            transition: 0.5s;
        }

        .login-btn:hover {
            background-color: @background-color-9
        }
    }

    .join-btn {
        border-radius: 100px;
        padding: 0px 20px 1px 20px;
        background-color: @btn-bg-color-3;
        border: none;
        color: white;
        font-weight: bold;
        transition: 0.5s;
    }

    .join-btn:hover {
        background-color: @background-color-9
    }

    .header-language-container {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 5px;
        cursor: pointer;

        svg {
            width: 20px;
            height: 15px;
            color: @txt-color-11;
        }

        .header-language-img {
            width: 36px;
            height: 36px;
        }
    }

    .header-language-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 900px) {
            display: none;
        }

        .header-language-options {
            display: flex;
            flex-direction: row;
            align-items: center;

            :first-child {
                border-right: 1px solid;
            }

            .header-language-item-selected,
            .header-language-item {
                padding: 0 10px;
                cursor: pointer;
            }

            .header-language-item-selected {
                color: @primary-color;
                font-size: 15px;
                font-family: 'WorkSans-SemiBold';
            }

            .header-language-item {
                color: @txt-color-9;
                font-size: 15px;
                font-family: 'WorkSans-SemiBold';
            }
        }
    }

}

.wallet-icon {
    -webkit-mask: url(../../../../assets/icons/icon-wallet.svg)no-repeat center/contain;
    background-color: @background-color;
}

.profile-icon {
    -webkit-mask: url(../../../../assets/icons/icon-profile.svg)no-repeat center/contain;
    background-color: @background-color-8;
}

.signout-icon {
    -webkit-mask: url(../../../../assets/icons/icon-signout.svg)no-repeat center/contain;
    background-color: @background-color-8;
}

.signout-icon,
.profile-icon,
.wallet-icon {
    height: 17px;
    width: 17px;
    transition: 0.6s;
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;