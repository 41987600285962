.dashboard-profile-container {
    .dashboard-profile-card-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        gap: 15px;

        @media only screen and (max-width: 820px) {
            flex-direction: column;
        }

        .dashboard-profile-card {
            background-color: @background-color;
            border-radius: 20px;
            box-shadow: 0 5px 12px rgb(0 0 0 / 15%);
            padding: 30px;
            min-width: 33%;

            .dashboard-profile-card-item {
                padding-bottom: 20px;

                .dashboard-profile-card-item-title {
                    color: @txt-color-2;
                    font-family: 'WorkSans-SemiBold';
                    font-size: 14px;
                }

                .dashboard-profile-card-item-content {
                    color: @txt-color-5;
                    font-size: 16px;
                    font-family: 'WorkSans-Regular';

                    .dashboard-profile-card-bank-acc-container {
                        display: flex;
                        flex-direction: row;
                        width: 100%;

                        .dashboard-profile-card-bank-acc-content {
                            margin-left: 10px;
                        }
                    }
                }
            }

            .dashboard-profile-card-buttons {
                display: flex;
                flex-direction: row;

                .dashboard-profile-card-buttons-more,
                .dashboard-profile-card-buttons-edit {
                    border-radius: 100px;
                    background-color: @btn-bg-color-2;
                    color: @txt-color-1;
                    transition: 0.3s;
                    cursor: pointer;
                }

                .dashboard-profile-card-buttons-edit {
                    padding: 10px 10px 4px 10px;

                    svg {
                        width: 23px;
                        height: 23px;
                    }
                }

                .dashboard-profile-card-buttons-more {
                    padding: 12px 15px 9px 15px;
                    margin-left: 10px;
                    font-size: 15px;
                    font-family: 'WorkSans-SemiBold';
                }

                .dashboard-profile-card-buttons-edit:hover,
                .dashboard-profile-card-buttons-more:hover {
                    background-color: @background-color-9;
                    border-color: @border-color-9;
                }
            }
        }
    }
}

.profile-edit-modal {
    .ant-modal-content {
        padding: 20px 20px 15px 20px;

        .ant-modal-close-x {
            width: 48px;
            height: 48px;
            line-height: 40px;
        }
        
        .ant-modal-body {
            padding: 0px;
            padding-top: 10px;

            .profile-edit-title {
                font-family: 'WorkSans-SemiBold';
                font-size: 18px;
                margin-bottom: 20px;
            }

            .ant-form-item-required {
                font-family: 'WorkSans-Regular';
                font-size: 16px;
                color: @txt-color-5;
                padding-right: 20px;
                padding-top: 5px;
            }

            .ant-form-item-required::before {
                display: none;
            }

            .ant-picker {
                padding: 8px 20px;
                border-radius: 5px;
                color: @txt-color-5;
            }

            .profile-edit-remark {
                font-family: 'WorkSans-Regular';
                font-size: 16px;
                margin-bottom: 20px;
                color: @txt-color-5;
            }
        }

        .ant-modal-footer {
            display: flex;
            flex-direction: row;
            justify-content: start;
            padding-top: 15px;
            padding-left: 0px;

            .ant-btn-primary {
                min-height: 30px;
                padding: 2px 15px 1px 15px;
                text-align: center;
                background-color: @btn-bg-color-1;
                border-color: @btn-bg-color-1;
                color: @button-text;
                font-family: 'WorkSans-SemiBold';
                border-radius: 100px;
                font-size: 15px;
                max-width: 100px;
                transition: 0.3s;
            }

            .ant-btn-default {
                min-height: 30px;
                padding: 2px 15px 1px 15px;
                text-align: center;
                font-family: 'WorkSans-SemiBold';
                color: @txt-color-8;
                background-color: @background-color-3;
                border-color: @background-color-3;
                border-radius: 100px;
                font-size: 15px;
                max-width: 100px;
                transition: 0.3s;
            }

            .ant-btn-primary:hover {
                background-color: @background-color-9;
                border-color: @border-color-9;
                color: @button-text ;
            }

            .ant-btn-default:hover {
                background-color: @background-color-8;
                border-color: @border-color-8;
                color: @button-text ;
            }
        }

    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;