@import '~antd/dist/antd.less';

@desktop: ~"only screen and (min-width: 992px)";
@tablet: ~"only screen and (min-width: 768px) and (max-width: 991px)";
@mobile: ~"only screen and (max-width: 767px)";

.ant-layout {
	background: @background-color;

	@media only screen and (max-width: 768px) {
		width: unset !important;
	}
}

.anonymous-container,
.maintenance-container {
	background-size: cover;
	background-position: center;
	height: 100vh;

	display: flex;
	justify-content: center;
	align-items: center;

	.anonymous-wrapper {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
	}

	@media only screen and (max-width: 576px) {
		padding-top: 0;

	}

}

.logo-container {
	margin-top: 3vh;
	margin-bottom: 3vh;
	text-align: center;

	img {
		max-width: 300px;
	}
}

.footer-container {
	margin: 3vh auto;
	text-align: center;
}

.app-container {
	@media only screen and (max-width: 576px) {
		height: 100%;
	}
}

.app-mobile-container {
	height: 100%;
	max-width: 100%;
	overflow-x: hidden;
}

.login-container {
	min-height: 100vh;

	@media only screen and (max-width: 576px) {
		height: 100%;
	}
}

.trigger {
	padding: 0 24px;
	font-size: 18px;
	line-height: 64px;
	cursor: pointer;
	transition: color 0.3s;
}

.trigger:hover {
	color: #1890ff;
}

.site-layout-background-login,
.site-layout-background {
	max-width: 100%;
	overflow-y: auto;
	min-height: 400px;
	background-color: #fafafa;
}

.site-layout-background {
	margin-top: 60px;
}

.page-container {
	background: @page-container-bg;
	max-width: 1280px;
	margin: auto;
	padding: 20px;
}

.filter-panel {
	margin-bottom: 20px;
	height: 100%;

	>div {
		margin: 8px !important;
	}

	.ant-form-item>.ant-form-item-label {
		display: flex;
		align-items: center;
	}

	.filter-button-group {
		width: 100%;
		color: white;

		Button {

			width: 100px !important;
			margin-right: 8px !important;

			&:last-child {
				margin-right: 0 !important;
			}
		}
	}
}

.ant-row-style {
	cursor: pointer;
}

.statistic-container {
	background-color: #f8f8f8; //rgb(240, 240, 240);
	padding: 10px 10px;
	margin-top: 8px;
	color: black;
	display: block;
	font-size: 12px;
	border-radius: 8px;

	.trade-summary {
		//font-weight: bold;
		font-size: 16px;
	}


	.message {

		display: flex;

		div {
			display: flex;
			flex-direction: column;
			padding-top: 4px;
			margin-right: 20px;
		}

		.msg-content {
			display: flex;
			flex-direction: column;
			margin-left: 20px;
		}

	}

}




.table-container {
	overflow: auto;
	max-height: 500px;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
	background-color: #f9f9f9;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range {
	color: black;
}

//.ant-table-thead th.ant-table-column-has-sorters:hover {
//	color: @side-nav-customer-service-text;
//}

.ant-table-thead th.ant-table-column-sort {
	color: black;
}

.maintenance-container {
	padding-top: 0;
	border-radius: 12px 0 0 12px;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 40px;

	@media only screen and (max-width: 576px) {
		.ant-result {
			padding: 0px;
		}

		padding: 8px;
	}

	.maintenance-text {
		margin-top: 16px;

		@media only screen and (max-width: 576px) {
			font-size: 16px;
		}

		span {
			display: block;
		}
	}

	.ant-result-title {
		color: white !important;
	}
}

.user-guide {
	display: none;
	color: #ffffff;
	border-color: #000000;
}

.cover-container-phone img {
	max-height: 150px;
	max-width: 280px;
	margin-top: -80px;
}

.auth-container .cover-container img {
	max-height: 220px;
	max-width: 280px;
	margin-top: -80px;
}

// .ant-btn-primary {
// 	color: @button-text;
// 	background-color: @btn-active-color;
// 	border: 1px solid @btn-active-color;
// }

.ant-carousel {
	.slick-dots {
		position: relative;
		top: -30px;

		li button {
			height: 16px;
			border-radius: 100px;
			border: 3px solid @carousel-dot-border;
			background-color: transparent;
		}

		li.slick-active {
			width: 16px;
			border-radius: 100px;
		}

		li.slick-active button {
			height: 16px;
			border-radius: 100px;
			border: 2px solid @carousel-dot-border;
			background-color: @primary-color;
		}
	}
}

::-webkit-scrollbar {
	width: 2px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 1px #696e75;
	border-radius: 10px;
	background: #ffffff;
}

::-webkit-scrollbar-thumb {
	background: #ffffff;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #cccccc;
}

.swiper-button-prev,
.swiper-button-next {
	color: @background-color-4  !important;
}

.swiper-pagination-bullet-active {
	background-color: @background-color-4  !important;
}

.show {
	display: block !important;
}

.hide {
	display: none !important;
}


.line-btn-stick {
	display: flex;
	flex-direction: column;
	position: fixed;
	top: 18%;
	right: 0px;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	background-color: @background-color;
	z-index: 1;
	color: @txt-color-1;
	font-family: 'WorkSans-Medium';
	font-size: 16px;
	min-width: 70px;
	min-height: 100px;
	justify-content: start;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 6px;

	.line-btn-txt {
		margin-top: 2px;
		padding-left: 15px;
	}

	.ant-divider {
		border-top: 1px solid @background-color-3;
		margin: 10px 0px;
	}

	.line-btn-container {
		margin: auto;
		margin-top: 12px;

		img {
			margin: auto;
			height: 30px;
			width: 30px;
		}
	}

	.line-btn-container:last-child {
		margin-top: 0;
		margin-bottom: 12px;
	}

	svg {
		position: fixed;
		font-size: 14px;
		bottom: 5px;
		color: @txt-color-2;
		z-index: 1;
	}
}

.line-qr-btn-stick {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	position: relative;
	position: fixed;
	right: 0;
	bottom: 40%;
	background-color: @background-color;
	padding: 10px 5px 5px 5px;
	z-index: 2;
	border-top-left-radius: 15px;
	border-bottom-left-radius: 15px;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 6px;

	@media only screen and (max-width: 900px) {
		bottom: 180px;
	}

	svg {
		position: absolute;
		font-size: 14px;
		color: @txt-color-4;
		z-index: 1;
		top: 4px;
		left: 4px;
	}

	.line-qr-txt-img {
		width: 60px;
		height: 16px;
		aspect-ratio: 60/16;
		margin-bottom: 5px;
	}

	.line-qr-qr-img-container {
		min-height: 85px;
		min-width: 85px;
		border: 2px solid @border-color-2;
		border-radius: 100px;
		background-color: transparent;

		.line-qr-qr-img {
			margin-top: 3px;
			margin-left: 3px;
			width: 75px;
			height: 75px;
			border-radius: 100px;
			aspect-ratio: 1;
		}

	}

	.line-qr-btn-stick-txt {
		color: @txt-color-4;
		text-align: center;
		font-family: 'WorkSans-Medium';
		font-size: 14px;
	}
}

.reward-btn-stick {
	display: flex;
	flex-direction: row;
	position: fixed;
	left: 80px;
	bottom: 30px;
	padding: 10px;
	z-index: 1;
	max-width: 100px;
	cursor: pointer;
	justify-content: flex-end;

	img {
		max-width: 150px;
	}

	svg {
		position: fixed;
		font-size: 18px;
		color: @txt-color-2;
		z-index: 1;
	}
}

.ant-table-row {
	.ant-table-cell {
		text-align: center;
	}
}

.view-more {
	display: flex;
	flex-direction: row;
	justify-content: end;
	padding-top: 10px;
	padding-right: 10px;

	.view-more-content {
		display: flex;
		flex-direction: row;
		cursor: pointer;
		margin-bottom: 10px;

		.view-more-text {
			color: @txt-color-4;
			margin-right: 5px;
		}

		.view-more-icon {
			display: flex;
			align-items: center;

			svg {
				margin: 0 auto;
				display: block;
				color: @txt-color-4;
			}
		}
	}
}

.negative-amt-txt {
	color: @txt-color-warning
}

.scroll-top-sticky-btn:hover {
	background-color: @background-color-9;
}

.scroll-top-sticky-btn {
	display: flex;
	flex-direction: column;
	position: relative;
	position: fixed;
	right: 22px;
	bottom: 100px;
	background-color:  @background-color-9;
	padding: 15px;
	z-index: 2;
	border-radius: 100px;
	transition: 0.3s;
	cursor: pointer;
	box-shadow: rgb(0 0 0 / 30%) 0px 4px 12px;

	svg {
		font-size: 30px;
		color: @background-color;
	}
}

.scroll-top-sticky-btn-hide {
	display: none;
}

.ant-table-thead > tr > th {
	background-color: @background-color-9;
	color: @txt-color-1;
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;