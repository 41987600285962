.home-container {
    background-color: @background-color;
    color: @menu-txt-color;

    .swiper {
        z-index: 0;
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;

            @media only screen and (max-width: 700px) {
                object-fit: cover;
                height: 200px;
            }
        }
    }

    .home-banner {
        .swiper-pagination-bullet-active {
            background-color: @background-color-4;
        }

        .swiper-pagination-bullet {
            background-color: @background-color-9;
            opacity: 1;
            border-radius: 0;
            min-width: 50px;
            max-height: 6px;
            margin: 0 6px !important;
        }

        .banner-card {
            position: relative;

            .banner-text {
                position: absolute;
                top: 20%;
                left: 12%;
                font-size: 42px;
                font-family: 'WorkSans-SemiBold';
                color: @txt-color-1;
                max-width: 500px;

                @media only screen and (max-width: 1280px) {
                    font-size: 36px;
                }

                @media only screen and (max-width: 900px) {
                    font-size: 32px;
                }

                @media only screen and (max-width: 780px) {
                    font-size: 22px;
                }

                @media only screen and (max-width: 700px) {
                    font-size: 20px;
                    left: 6%;
                    max-width: 300px;
                }
            }

            .banner-btn {
                border-radius: 100px;
                padding: 15px 35px;
                background-color: #ebb23d;
                color: @txt-color-1;
                font-weight: bold;
                border: none;
                cursor: pointer;
                position: absolute;
                bottom: 20%;
                left: 12%;
                transition: 0.5s;

                @media only screen and (max-width: 700px) {
                    padding: 10px 30px;
                    left: 6%;
                    bottom: 15%;
                }
            }

            .banner-btn:hover {
                background-color: @background-color-9
            }
        }
    }

    .home-earn {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 60px 0px;

        .home-earn-title {
            font-family: 'WorkSans-SemiBold';
            font-size: 32px;
            color: @txt-color-2;
            text-align: center;

            @media only screen and (max-width: 800px) {
                max-width: 85%;
                font-size: 24px;
            }
        }

        .home-earn-content {
            font-family: 'WorkSans-Regular';
            font-size: 16px;
            max-width: 650px;
            text-align: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 800px) {
                max-width: 85%;
            }
        }

        .home-earn-card-container {
            width: 70%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 20px;

            @media only screen and (max-width: 800px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
            }

            .home-earn-card {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;

                .earn-card-img {
                    img {
                        max-width: 100px;
                    }
                }

                .earn-card-title {
                    font-size: 18px;
                    font-family: 'WorkSans-SemiBold';
                }

                .earn-card-content {
                    font-size: 16px;
                    font-family: 'WorkSans-Regular';
                }
            }
        }
    }

    .home-commission {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 60px 0px;
        background-color: @background-color-4;

        .home-commission-title {
            font-family: 'WorkSans-SemiBold';
            font-size: 32px;
            color: @txt-color-1;
            text-align: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 800px) {
                max-width: 85%;
                font-size: 24px;
            }
        }

        .home-commission-content {
            font-family: 'WorkSans-Regular';
            font-size: 16px;
            max-width: 650px;
            text-align: center;
            margin-bottom: 40px;
            color: @txt-color-1;

            @media only screen and (max-width: 800px) {
                max-width: 85%;
            }
        }

        .home-commission-card-container {
            width: 70%;
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            align-items: flex-start;
            gap: 20px;

            @media only screen and (max-width: 800px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
            }

            .home-commission-card {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 15px;

                .commission-card-img {
                    img {
                        max-width: 100px;
                    }
                }

                .commission-card-prefix {
                    font-size: 16px;
                    font-family: 'WorkSans-Regular';
                    color: @txt-color-1;
                }

                .commission-card-title {
                    font-size: 36px;
                    font-family: 'WorkSans-SemiBold';
                    color: @txt-color-1;
                }

                .commission-card-content {
                    font-size: 16px;
                    font-family: 'WorkSans-Regular';
                    color: @txt-color-1;
                }
            }
        }
    }

    .home-benefit {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 60px 0px;
        background-color: @background-color-15;

        .home-benefit-title {
            font-family: 'WorkSans-SemiBold';
            font-size: 32px;
            color: @txt-color-2;
            text-align: center;

            @media only screen and (max-width: 800px) {
                max-width: 85%;
                font-size: 24px;
            }
        }

        .home-benefit-content {
            font-family: 'WorkSans-Regular';
            font-size: 16px;
            max-width: 1200px;
            text-align: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 1200px) {
                max-width: 70%;
            }

            @media only screen and (max-width: 800px) {
                max-width: 85%;
            }
        }

        .home-benefit-card-container {
            width: 65%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 20px;

            @media only screen and (max-width: 800px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;
            }

            .home-benefit-card {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                max-width: 372px;
                min-height: 320px;

                .benefit-card-img {
                    img {
                        max-width: 372px;
                    }
                }

                .benefit-card-title {
                    width: 100%;
                    font-size: 18px;
                    font-family: 'WorkSans-SemiBold';
                    text-align: center;
                    background-color: @background-color;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    flex-wrap: wrap;
                    flex: 1;

                    .benefit-card-title-txt {
                        width: 80%;
                        color: @txt-color-2;
                    }
                }
            }
        }
    }

    .home-products {
        max-width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;
        padding: 60px 0px;

        .home-products-title {
            font-family: 'WorkSans-SemiBold';
            font-size: 32px;
            color: @txt-color-2;
            text-align: center;

            @media only screen and (max-width: 800px) {
                max-width: 85%;
                font-size: 24px;
            }
        }

        .home-products-content {
            font-family: 'WorkSans-Regular';
            font-size: 16px;
            max-width: 1200px;
            text-align: center;
            margin-bottom: 40px;

            @media only screen and (max-width: 1200px) {
                max-width: 70%;
            }

            @media only screen and (max-width: 800px) {
                max-width: 85%;
            }
        }

        .home-products-card-container {
            width: 65%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            align-items: flex-start;
            gap: 20px;


            @media only screen and (max-width: 1600px) {
                width: 75%;
            }

            @media only screen and (max-width: 800px) {
                width: 100%;
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                align-items: center;
                gap: 40px;
            }

            .home-products-card {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-start;
                gap: 40px;
                max-width: 500px;
                //flex: 1;

                @media only screen and (max-width: 2000px) {
                    min-width: 500px;
                }

                @media only screen and (max-width: 1600px) {
                    min-width: 200px;
                    max-width: 450px;
                }
                
                @media only screen and (max-width: 800px) {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 20px;
                }

                .products-card-img {
                    @media only screen and (max-width: 800px) {
                        max-width: 90%;
                    }

                    img {
                        max-width: 270px;

                        @media only screen and (max-width: 800px) {
                            max-width: none;
                            width: 100%;
                        }
                    }
                }

                .products-card-content-container {
                    flex: 2;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: 20px;
                    align-items: flex-start;
                    padding: 10px;

                    @media only screen and (max-width: 800px) {
                        width: 90%;
                        padding: 0px;
                        gap: 10px
                    }

                    .products-card-title {
                        font-size: 18px;
                        font-family: 'WorkSans-SemiBold';
                        text-align: center;
                        background-color: @background-color;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        flex-wrap: wrap;
                        color: @txt-color-2;
                    }

                    .products-card-content {
                        font-size: 12px;
                    }

                    .products-card-btn {
                        border-radius: 100px;
                        padding: 15px 20px;
                        background-color: #ebb23d;
                        color: @txt-color-1;
                        font-weight: bold;
                        border: none;
                        cursor: pointer;
                        transition: 0.5s;
                        font-size: 12px;

                        @media only screen and (max-width: 1600px) {
                            padding: 10px 15px;
                            font-size: 9px;
                        }
                    }

                    .products-card-btn:hover {
                        background-color: @background-color-9;
                    }
                }
            }
        }
    }

    .home-bottom {
        background-image: url('../../assets/images/bottom-img.jpg');
        background-position: 50% 50%;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 20px;
        color: @txt-color-1;
        min-height: 600px;

        @media only screen and (max-width: 800px) {
            min-height: 400px;
            padding: 0px 30px;
        }

        .home-bottom-title {
            font-family: 'WorkSans-SemiBold';
            font-size: 32px;
            text-align: center;

            @media only screen and (max-width: 800px) {
                font-size: 26px;
            }
        }

        .home-bottom-content {
            font-family: 'WorkSans-Regular';
            font-size: 16px;
            text-align: center;
        }

        .home-bottom-btn {
            margin-top: 20px;
            border-radius: 100px;
            padding: 15px 20px;
            background-color: #ebb23d;
            color: @txt-color-1;
            font-weight: bold;
            border: none;
            cursor: pointer;
            transition: 0.5s;
        }

        .home-bottom-btn:hover {
            background-color: @background-color-9;
        }
    }
}


.carousel-image-inactive {
    filter: gray;
    -webkit-filter: grayscale(100%);
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;