.site-maintenance-container {
    display: flex;
    flex-direction: column;
    max-width: 1280px;
    justify-content: space-between;
    padding: 0;
    margin: auto;
    min-height: 650px;

    .maintenance-logo {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        margin: 20px;
        align-items: center;
        padding-bottom: 30px;

        img {
            max-width: 130px;
        }
    }

    .maintenance-content {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 50px;

        .maintenance-img {
            display: flex;
            justify-content: center;
            flex: 1;

            img {
                max-width: 450px;
            }
        }

        .maintenance-detail {
            flex: 1;

            .maintenance-detail-title {
                font-family: 'WorkSans-Bold';
                font-size: 34px;
                text-align: center;
                background-color: @background-color-4;
                margin-bottom: 10px;
            }

            .maintenance-detail-duration {
                text-align: center;
                font-family: 'WorkSans-SemiBold';
                font-size: 24px;
            }

            .maintenance-detail-date {
                text-align: center;
                font-family: 'WorkSans-SemiBold';
                font-size: 32px;
            }

            .maintenance-countdown-container {
                display: flex;
                flex-direction: row;
                width: 100%;
                align-items: center;
                justify-content: center;
                margin-top: 15px;

                .maintenance-countdown-divider {
                    position: relative;
                    bottom: 21px;
                    padding-left: 10px;
                    font-size: 60px;
                    font-family: 'WorkSans-Regular';
                }

                .maintenance-countdown-item-container {
                    display: flex;
                    flex-direction: column;

                    .maintenance-countdown-digit-container {
                        display: flex;
                        flex-direction: row;

                        .maintenance-countdown-digits {
                            max-height: 70px;
                            background-color: @background-color-5;
                            color: @txt-color-1;
                            font-size: 60px;
                            margin-left: 5px;
                            padding-left: 10px;
                            padding-right: 10px;
                            border-radius: 10px;
                            font-family: 'WorkSans-SemiBold';

                            span {
                                position: relative;
                                bottom: 11px;
                            }
                        }
                    }

                    .maintenance-countdown-digit-desc {
                        margin-top: 10px;
                        text-align: center;
                        font-family: 'WorkSans-Regular';
                        font-size: 16px;
                    }
                }
            }

            .maintenance-detail-desc-title {
                font-family: 'WorkSans-Medium';
                font-size: 20px;
                padding: 0 10px;
                text-align: center;
                margin-top: 20px;
            }

            .maintenance-detail-desc-content {
                font-family: 'WorkSans-Regular';
                font-size: 16px;
                padding: 10px;
                line-height: 1.5;
                text-align: center;
            }
        }
    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;