.ant-layout-header {
    height: 70px;
}

.site-layout-header {
    //max-width: @max-width;
    position: fixed;
    top: 0px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: @background-color !important;
    width: 100%;
    padding: 0px 30px !important;
    z-index: 99;
    box-shadow: rgb(100 100 111 / 10%) 0px 3px 5px 0px;

    @media only screen and (max-width: 820px) {
        padding: 20px 10px 20px 15px !important;
    }

    .header-left-elements {
        display: flex;
        justify-content: left;
        align-items: start;
        max-width: 50%;
        color: #666666;
        font-family: 'WorkSans-Regular';
        font-size: 15px;

        .header-logo {
            cursor: pointer;
            height: 50px;
        }
    }

    .header-right-elements {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: right;
        align-items: right;
        color: #FB7204;
        margin: 0px 10px 0 0;
        width: 100%;

        @media only screen and (max-width: 1150px) {
            flex: 3;
        }

        @media only screen and (max-width: 820px) {
            flex: 4;
        }

        .ant-form,
        .header-forgot-password {
            width: 100%;
        }

        .header-right-first-row {
            flex: 2;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;
            width: 100%;
            gap: 15px;

            .home-menu-container {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                gap: 20px;

                @media only screen and (max-width: 900px) {
                    display: none;
                }

                .home-menu-items {
                    font-family: 'WorkSans-Medium';
                    font-size: 14px;
                    cursor: pointer;
                    transition: 0.5s;
                }

                .home-menu-items:hover {
                    color: @txt-color-10
                }
            }

            .ant-row {
                input {
                    max-width: 140px !important;
                    padding: 5px 8px !important;
                }
            }

            .login-register-wrapper {
                display: flex;
                gap: 4px;
            }

            .login-header-elements {
                display: flex;
                flex-direction: row;
                gap: 10px;

                .user-container {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    gap: 10px;
                    padding: 4px 12px;
                    z-index: 1;
                    color: @txt-color-4;
                    cursor: pointer;

                    &::after {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        background-color: @background-color-5;
                        opacity: 0.65;
                        border-radius: 50px;
                        opacity: 0;
                        transform: translateY(0);
                        transition: all 0.4s linear;
                    }

                    &:hover {
                        color: white;

                        &::after {
                            opacity: 1;
                            transform: translateY(1);
                        }
                    }

                    .login-user-icon {
                        margin-top: 4px;
                    }

                    .login-username {
                        font-family: 'WorkSans-Medium';
                        font-size: 15px;
                        font-style: normal;
                        padding-top: 4px;
                        cursor: pointer;
                    }

                }

                .login-inbox-btn {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 100px;
                    background-color: @background-color-5;
                    color: @txt-color-1;
                    padding: 4px 8px;
                    cursor: pointer;
                    transition: all 0.6s ease-in-out;

                    &:hover {
                        background-color: @background-color-8;

                    }

                    svg {
                        font-size: 19px;
                    }
                }

                .login-wallet-btn {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    border-radius: 100px;
                    background-color: @background-color-7;
                    gap: 20px;
                    color: @txt-color-11;
                    border: 1px solid @border-color-7;
                    padding: 0px 13px 0 15px;
                    font-family: 'WorkSans-SemiBold';
                    font-size: 15px;
                    cursor: pointer;

                    .login-wallet-btn-amt {
                        padding-top: 2px;
                    }

                    svg {
                        font-size: 17px;
                    }

                }

                .logout-btn {
                    border: 1px solid @border-color-7;
                    color: @txt-color-9;
                    border-radius: 100px;
                    font-size: 15px;
                    min-height: 36px;
                    padding: 6px 15px 7px 15px;
                    font-family: 'WorkSans-SemiBold';
                    cursor: pointer;
                }

                .logout-btn:hover {
                    color: @txt-color-1;
                    background-color: @background-color-8;
                    border-color: @border-color-8;
                }
            }

            .header-mobile-icon {
                max-height: 45px;
            }

            .header-forgot-password {
                font-size: 13px;
                color: @input-border-color-focus;
                width: 100%;
                text-align: center;
                cursor: pointer;
                font-family: 'WorkSans-Regular';
                margin-top: 3px;
            }

            .header-language-img {
                width: 32px;
                height: 32px;
            }

            .ant-form-item {
                margin-bottom: 0px;
                text-transform: uppercase;
                color: #ffffff;
                font-size: 13px;
                font-family: 'WorkSans-Regular';

                .ant-input {
                    border-radius: 100px;
                    padding: 4px 8px;
                    width: 220px;
                }

                .ant-input:focus {
                    border-color: @primary-color;
                }

                .ant-input-password {
                    border-radius: 100px;
                    padding: 0;

                    .ant-input-suffix {
                        display: none;
                    }
                }

                .login-btn {
                    border-radius: 100px;
                    padding: 0px 20px;
                    background-color: @btn-bg-color-1;
                    font-weight: bold;
                    border: none;
                    transition: 0.5s;
                }

                .login-btn:hover {
                    background-color: @background-color-9
                }
            }

            .join-btn {
                border-radius: 100px;
                padding: 0px 20px 1px 20px;
                background-color: @btn-bg-color-3;
                border: none;
                color: white;
                font-weight: bold;
                transition: 0.5s;
            }

            .join-btn:hover {
                background-color: @background-color-9
            }

            .header-language-wrapper {
                display: flex;
                justify-content: center;
                align-items: center;

                @media only screen and (max-width: 900px) {
                    display: none;
                }

                .header-language-options {
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    :first-child {
                        border-right: 1px solid;
                    }

                    .header-language-item-selected,
                    .header-language-item {
                        padding: 0 10px;
                        cursor: pointer;
                    }

                    .header-language-item-selected {
                        color: @primary-color;
                        font-size: 15px;
                        font-family: 'WorkSans-SemiBold';
                    }

                    .header-language-item {
                        color: @txt-color-9;
                        font-size: 15px;
                        font-family: 'WorkSans-SemiBold';
                    }
                }
            }

        }

        .header-right-second-row {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            padding: 0 60px 0 60px;

            .header-forgot-password {
                flex: 1;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                padding-left: 20px;
                max-height: 40px;
            }

            .header-datetime {
                flex: 1;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding-right: 20px;
                max-height: 40px;
            }
        }
    }
}

.forgot-password-modal {
    min-height: 600px !important;
    min-width: 600px !important;

    .ant-modal-close-x {
        svg {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }

    .forgot-password-modal-container {
        min-height: 550px !important;
        min-width: 550px !important;
        display: flex;
        flex-direction: column;
        background: url(../../../assets/images/innerpage-register-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: scroll;

        .forgot-password-modal-content-container {
            min-width: 460px;
            min-height: 430px;
            margin: auto;
            background-color: @background-color;
            border: 2px solid @border-color-2;
            border-radius: 15px;
            padding: 20px;

            .forgot-password-modal-title {
                font-family: 'WorkSans-Bold';
                font-size: 24px;
                color: @txt-color-13;
                margin-top: 30px;
                margin-bottom: 40px;
            }

            .forgot-password-modal-content {
                margin-right: 30px;
                display: flex;
                flex-direction: column;

                .forgot-password-form-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: flex-start;

                    .form-username-icon,
                    .form-password-icon,
                    .form-aff-icon {
                        width: 16px;
                        margin-top: 24px;
                    }

                    .form-email-icon {
                        width: 16px;
                        margin-top: 30px;
                    }

                    .form-calendar-icon {
                        width: 16px;
                        margin-top: 38px;
                    }

                    .ant-form-item {
                        width: 100%;
                        padding-left: 10px;
                        margin-bottom: 0px;
                    }

                    .float-label {
                        position: relative;
                        padding-top: 12px;
                        margin-bottom: 12px;
                        overflow: hidden;
                    }

                    .label {
                        font-family: 'WorkSans-Medium';
                        font-size: 15px;
                        font-weight: normal;
                        position: absolute;
                        pointer-events: none;
                        left: 0px;
                        bottom: 4px;
                        transition: 0.4s ease all;
                        color: @input-label-color;
                    }

                    .phone-label {
                        left: 8px !important;
                        margin-left: 100px;
                    }

                    .otp-input {
                        width: 90% !important;
                    }

                    .label-float {
                        top: 0;
                        // left: -100%;
                        transform: translateY(1);
                        transition: top 0.6s ease;
                    }

                    .otp-request-btn {
                        min-height: 35px;
                        background-color: @background-color-5;
                        padding: 0px 15px;
                        border-radius: 100px;
                        font-size: 14px;
                        color: @txt-color-1;
                        font-family: 'WorkSans-SemiBold';
                        margin-top: 15px;
                    }

                    .input {
                        position: relative;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-bottom: 1px solid @input-border-color;
                        background-color: transparent;
                        box-shadow: none;
                        // transition: 0.5s;
                        width: 100%;
                        margin-top: 15px;
                        padding: 0px;
                        z-index: 0;
                    }

                    .ant-input-password {
                        margin-top: 10px;
                    }

                    .input-underline {
                        position: absolute;
                        left: -100%;
                        transform: translateX(100%);
                        transform-origin: -100px;
                        transition: transform 0.3s linear;
                        border-bottom: 2px solid @primary-color;
                        overflow: hidden;
                        height: 2px;
                        width: 100%;
                        bottom: 0;
                    }

                    .ant-input-group {
                        z-index: 0;
                        margin-top: 12px;

                        .ant-select-selector {
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-bottom: 1px solid @input-border-color;
                        }

                        .ant-input {
                            position: relative;
                            background-color: transparent;
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-bottom: 1px solid @input-border-color;

                            &:focus {
                                box-shadow: unset;
                            }

                            &:hover {
                                z-index: 0;
                            }
                        }
                    }

                    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
                        box-shadow: none;
                    }

                    .float-label.ant-picker {
                        width: 100%;
                    }

                    .ant-picker-input input::placeholder {
                        color: transparent;
                    }

                    .register-name-note {
                        padding-left: 25px;
                        font-size: 11px;
                        padding-bottom: 25px;
                    }
                }

                .form-mid-button {
                    display: flex;
                    justify-content: center;
                }

                .forgot-password-submit-btn {
                    margin: auto;
                    margin-top: 20px !important;
                    min-width: 100px;
                    width: 50%;
                    min-height: 40px;
                    text-align: center;
                    background-color: @background-color-5;
                    padding: 0 15px;
                    border-radius: 100px;
                    font-size: 14px;
                    color: @txt-color-1;
                    font-family: 'WorkSans-SemiBold';
                    cursor: pointer;
                }

                .forgot-password-next-btn {
                    margin: auto;
                    margin-top: 20px !important;
                    min-width: 100px;
                    max-width: 150px;
                    text-align: center;
                    background-color: @background-color-5;
                    padding: 10px 15px;
                    border-radius: 100px;
                    font-size: 14px;
                    color: @txt-color-1;
                    font-family: 'WorkSans-SemiBold';
                    cursor: pointer;
                }
            }
        }
    }
}


.mobile-menu-item-first,
.mobile-menu-item {
    display: flex;
    flex-direction: row;
    padding-bottom: 10px;
    margin-right: 15px;
    border-bottom: 1px solid @input-border-color;

    .mobile-menu-item-txt {
        margin-left: 10px;
        font-size: 14px;
        font-family: 'WorkSans-Regular';
    }

    img {
        width: 24px;
    }
}

.mobile-menu-item {
    padding-top: 10px;
}

.ant-drawer-header-title {
    justify-content: right !important;
}

.mobile-login-modal {
    display: flex;
    flex-direction: column;

    .ant-modal-close-x {
        svg {
            margin-left: 20px;
            margin-bottom: 10px;
        }
    }

    .mobile-login-title {
        display: flex;
        justify-content: center;

        .mobile-login-logo {
            max-width: 130px;
        }
    }

    .mobile-login-divider {
        background-color: #d9e5e5e5;
        height: 1px;
        width: 100%;
        margin: 20px 0px;
    }

    .mobile-login-form-content-container {
        input {
            padding: 8px 22px !important;
        }

        .ant-input-affix-wrapper {
            padding: 0 !important;
            padding-right: 10px !important;
        }

        .mobile-login-form-forgot {
            text-align: center;
        }
    }

    .mobile-login-btn {
        width: 100% !important;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .m-forgot-password-modal-container {
        display: flex;
        flex-direction: column;
        background: url(../../../assets/images/innerpage-register-bg.jpg);
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        background-attachment: scroll;

        .m-forgot-password-modal-content-container {
            margin: auto;
            margin-top: 20px;
            margin-bottom: 20px;
            width: 90%;
            background-color: @background-color;
            border: 2px solid @border-color-2;
            border-radius: 15px;
            padding: 20px;

            .m-forgot-password-modal-title {
                font-family: 'WorkSans-Bold';
                font-size: 24px;
                color: @txt-color-13;
                margin-top: 30px;
                margin-bottom: 40px;
            }

            .m-forgot-password-modal-content {
                margin-right: 10px;
                display: flex;
                flex-direction: column;

                .m-forgot-password-form-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: start;
                    align-items: flex-start;

                    .m-form-username-icon,
                    .m-form-password-icon,
                    .m-form-aff-icon {
                        width: 16px;
                        margin-top: 24px;
                    }

                    .m-form-email-icon {
                        width: 16px;
                        margin-top: 30px;
                    }

                    .m-form-calendar-icon {
                        width: 16px;
                        margin-top: 38px;
                    }

                    .ant-form-item {
                        width: 100%;
                        padding-left: 10px;
                        margin-bottom: 0px;
                    }

                    .m-float-label {
                        position: relative;
                        padding-top: 12px;
                        margin-bottom: 12px;
                        overflow: hidden;
                    }

                    .m-label {
                        font-family: 'WorkSans-Medium';
                        font-size: 15px;
                        font-weight: normal;
                        position: absolute;
                        pointer-events: none;
                        left: 0px;
                        bottom: 4px;
                        transition: 0.4s ease all;
                        color: @input-label-color;
                    }

                    .m-phone-label {
                        left: 8px !important;
                        margin-left: 100px;
                    }

                    .m-otp-input {
                        width: 90% !important;
                    }

                    .m-label-float {
                        top: 0;
                        // left: -100%;
                        transform: translateY(1);
                        transition: top 0.6s ease;
                    }

                    .otp-request-btn {
                        min-height: 35px;
                        background-color: @background-color-5;
                        padding: 0px 15px;
                        border-radius: 100px;
                        font-size: 14px;
                        color: @txt-color-1;
                        font-family: 'WorkSans-SemiBold';
                        margin-top: 15px;
                    }

                    .m-input {
                        position: relative;
                        border-top: none;
                        border-left: none;
                        border-right: none;
                        border-bottom: 1px solid @input-border-color;
                        background-color: transparent;
                        box-shadow: none;
                        // transition: 0.5s;
                        width: 100%;
                        margin-top: 15px;
                        padding: 0px;
                        z-index: 0;
                    }

                    .ant-input-password {
                        margin-top: 10px;
                    }

                    .m-input-underline {
                        position: absolute;
                        left: -100%;
                        transform: translateX(100%);
                        transform-origin: -100px;
                        transition: transform 0.3s linear;
                        border-bottom: 2px solid @primary-color;
                        overflow: hidden;
                        height: 2px;
                        width: 100%;
                        bottom: 0;
                    }

                    .ant-input-group {
                        z-index: 0;
                        margin-top: 12px;

                        .ant-select-selector {
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-bottom: 1px solid @input-border-color;
                        }

                        .ant-input {
                            position: relative;
                            background-color: transparent;
                            border-top: none;
                            border-left: none;
                            border-right: none;
                            border-bottom: 1px solid @input-border-color;

                            &:focus {
                                box-shadow: unset;
                            }

                            &:hover {
                                z-index: 0;
                            }
                        }
                    }

                    .ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:focus {
                        box-shadow: none;
                    }

                    .float-label.ant-picker {
                        width: 100%;
                    }

                    .ant-picker-input input::placeholder {
                        color: transparent;
                    }

                    .m-register-name-note {
                        padding-left: 25px;
                        font-size: 11px;
                        padding-bottom: 25px;
                    }
                }

                .m-form-mid-button {
                    display: flex;
                    justify-content: center;
                }

                .m-forgot-password-submit-btn {
                    margin: auto;
                    margin-top: 20px !important;
                    min-width: 100px;
                    width: 50%;
                    min-height: 40px;
                    text-align: center;
                    background-color: @background-color-5;
                    padding: 0 15px;
                    border-radius: 100px;
                    font-size: 14px;
                    color: @txt-color-1;
                    font-family: 'WorkSans-SemiBold';
                    cursor: pointer;
                }

                .m-forgot-password-next-btn {
                    margin: auto;
                    margin-top: 20px !important;
                    min-width: 100px;
                    max-width: 150px;
                    text-align: center;
                    background-color: @background-color-5;
                    padding: 10px 15px;
                    border-radius: 100px;
                    font-size: 14px;
                    color: @txt-color-1;
                    font-family: 'WorkSans-SemiBold';
                    cursor: pointer;
                }
            }
        }
    }
}

.m-drawer-language-modal {
    .ant-modal-body {
        padding: 30px 24px;

        .m-drawer-language-title {
            font-size: 16px;
            font-family: 'WorkSans-SemiBold';
            color: @txt-color-2;
            margin-bottom: 18px;
        }

        .m-drawer-language-container {
            display: flex;
            flex-direction: column;

            .m-drawer-language-options {
                display: flex;
                flex-direction: row;
                font-size: 16px;
                font-family: 'WorkSans-SemiBold';
                color: @txt-color-2;
                border-bottom: 1px solid @border-color-4;
                padding: 15px 5px;
            
                .m-drawer-language-txt {
                    margin-top: 2px;
                }

                .m-drawer-language-img {
                    max-width: 30px;
                    margin-right: 20px;
                }
            }

            .m-drawer-language-options:last-child {
                border-bottom: none;
            }
        }
    }
}

.mobile-menu-btn {
    display: none;
    
    @media only screen and (max-width: 900px) {
        display: block;
    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;