.dashboard-wd-container {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;

    .table-container {
        width: 100%;
        box-shadow: 0 6px 8px rgb(0 0 0 / 15%);
    }

    .wd-action-col {
        svg {
            font-size: 20px;
        }
    }

    .dashboard-wd-add-bank-container {
        display: flex;
        flex-direction: row;
        justify-content: start;

        .dashboard-wd-add-bank-btn:hover {
            background-color: @background-color-9;
            border-color: @border-color-8;
        }

        .dashboard-wd-add-bank-btn {
            cursor: pointer;
            display: flex;
            flex-direction: row;
            background-color: @background-color-5;
            margin-bottom: 20px;
            margin-top: 15px;
            padding: 10px 20px 8px 20px;
            border-radius: 15px;
            transition: 0.3s;

            .dashboard-wd-add-bank-txt {
                font-size: 15px;
                font-family: 'WorkSans-SemiBold';
                margin-left: 3px;
                color: @txt-color-1;
            }

            svg {
                font-size: 20px;
                color: @txt-color-1;
            }
        }
    }
}

.dashboard-wd-modal-title {
    font-family: 'WorkSans-SemiBold';
    font-size: 18px;
    margin-bottom: 20px;
    padding-bottom: 15px;
    border-bottom: 1px solid @border-color-5;
}

.dashboard-wd-form-items {
    display: flex;
    flex-direction: row;

    .dashboard-wd-form-label {
        font-family: 'WorkSans-SemiBold';
        font-size: 16px;
        padding-top: 10px;
        color: @txt-color-2;
        width: 250px;
    }

    .dashboard-wd-otp-form-label {
        font-family: 'WorkSans-SemiBold';
        font-size: 16px;
        padding-top: 10px;
        color: @txt-color-2;
        width: 192px;
    }

    .otp-input-btn-container {
        display: flex;
        flex-direction: row;

        .dashboard-otp-input {
            padding: 10px;
            border-radius: 5px;
            max-width: 160px;
            margin-right: 20px;
        }
    }

    .ant-form-item {
        width: 100%;
    }

    .dashboard-wd-input {
        padding: 10px;
        border-radius: 5px;
        width: 100%;
    }

    .ant-input::placeholder {
        color: @menu-txt-color;
        font-size: 16px;
    }

    .dashboard-wd-add-bank-dropdown {
        width: 100%;
    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;