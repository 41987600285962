.site-layout-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 0px;
    color: @txt-color-15;
    font-family: 'WorkSans-Regular';
    font-size: 16px;
    background-color: @background-color-14;

    .footer-provider-items {
        padding: 10px;

        .provider-icon {
            width: 52px;
            height: 52px;
            aspect-ratio: 1;
        }
    }

    .footer-first-row {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: center;
        align-items: center;
        background-color: @background-color-9;
        font-family: 'WorkSans-Regular';
        font-size: 14px;

        
        @media only screen and (max-width: 830px) {
            width: 100%;
            max-width: 820px;
            overflow: hidden;
            font-size: 12px;

        }    

        .footer-first-row-left-element {
            display: flex;
            flex-direction: row;
            justify-content: space-evenly;
            padding: 15px 0;

            .footer-first-row-left-element-items,
            .footer-first-row-left-element-item-last {
                padding: 0 20px;

                a {
                    color: white !important;
                }
            }

            .footer-first-row-left-element-items:hover,
            .footer-first-row-left-element-item-last:hover {
                a {
                    color: @primary-color  !important;
                }
            }
        }

        .footer-right-element {
            flex: 1;
            display: flex;
            flex-direction: row;
            justify-content: flex-end;
            align-items: center;

            .span {
                padding-right: 10px;
            }

            .footer-rights-reserved {
                padding-left: 10px;
            }
        }
    }

    .footer-second-row {
        display: flex;
        flex-direction: column;
        padding-top: 20px;
        width: 100%;
        justify-content: center;
        align-items: center;

        .footer-provider-title {
            text-transform: uppercase;
            padding-top: 15px;
        }

        .footer-provider-items-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;

            .footer-provider-items {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 40px;
                gap: 15px;

                .footer-provider-item-txt {
                    padding-top: 5px;
                    font-size: 12px;
                    font-family: 'WorkSans-Medium';
                    text-align: center;
                }
            }
        }
    }

    .footer-third-row {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-left: 250px;
        padding-right: 250px;
        width: 65%;
        justify-content: center;
        align-items: center;
        padding-bottom: 50px;

        .footer-third-row-item-left {
            flex: 3;
        }

        .footer-third-row-item-right {
            flex: 2;
        }

        .footer-third-row-item-right,
        .footer-third-row-item-left {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            width: 100%;

            .footer-third-row-item-logo {
                max-height: 400px;
                max-width: 400px;
            }

            .footer-third-row-item-title {
                display: flex;
                flex-direction: row;
                margin-bottom: 18px;

                .footer-customer-service-bold {
                    padding-left: 5px;
                    font-weight: bold;
                }
            }

            .footer-third-row-item-content {
                margin-bottom: 10px;
            }

            .footer-third-row-icons {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .footer-forth-row {
        display: flex;
        flex-direction: column;
        padding-top: 30px;
        width: 100%;
        justify-content: center;
        align-items: center;

        .footer-forth-row-icons,
        .footer-forth-row-copyright {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding-bottom: 30px;
        }

        .footer-forth-row-copyright {
            font-size: 12px;
        }
    }

    .footer-content {
        max-width: @max-width;
        margin: auto;
    }

    .footer-social-media {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-left: 250px;
        padding-right: 250px;
        width: 100%;
        justify-content: center;
        align-items: center;

        .footer-social-media-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            width: 100%;

            .footer-social-media-item-logo {
                max-height: 400px;
                max-width: 400px;
            }

            .footer-social-media-item-title {
                display: flex;
                flex-direction: row;
                margin-bottom: 18px;

                .footer-customer-service-bold {
                    padding-left: 5px;
                    font-weight: bold;
                }
            }

            .footer-social-media-item-content {
                margin-bottom: 10px;
            }

            .footer-social-media-icons {
                display: flex;
                flex-direction: row;
            }
        }
    }

    .footer-providers {
        display: flex;
        flex-direction: row;
        padding-top: 20px;
        padding-left: 120px;
        padding-right: 120px;
        width: 100%;
        justify-content: center;
        align-items: center;

        @media only screen and (max-width: 820px) {
            padding-left: 20px;
            padding-right: 20px;
        }

        .footer-providers-item {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding-top: 20px;
            width: 100%;

            .footer-providers-icon {
                width: 120px;
                height: 48px;
                aspect-ratio: 2.5;
            }

            .footer-providers-item-logo {
                max-height: 400px;
                max-width: 400px;
            }

            .footer-providers-item-title {
                display: flex;
                flex-direction: row;
                margin-bottom: 18px;

                .footer-customer-service-bold {
                    padding-left: 5px;
                    font-weight: bold;
                }
            }

            .footer-providers-item-content {
                margin-bottom: 10px;
            }

            .footer-providers-icons {
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: center;
            }
        }
    }
}
@max-width: 1280px;@primary-color: #e79135;@background-color: #ffffff;@background-color-2: #FAFAFA;@background-color-3: #eeeeee;@background-color-4: #e79235;@background-color-5: #EBAD3C;@background-color-6: #03ba00;@background-color-7: #f1f4f7;@background-color-8: #000000;@background-color-9: #040162;@background-color-10: #aaa;@background-color-11: #F8F8F8;@background-color-12: #ffffff99;@background-color-13: #840536;@background-color-14: #1B1B1B;@background-color-15: #E8E8E8;@menu-txt-color: #666666;@page-container-bg: #000000;@button-text: #ffffff;@btn-active-color: #ffffff;@menu-btm-border: #c6c6c6;@btn-bg-color-1: #ebb23d;@btn-bg-color-2: #edad5d;@btn-bg-color-3: #e79235;@btn-bg-color-4: #479be9;@btn-bg-color-5: #e34a29;@btn-bg-color-6: #EBAD3C;@input-border-color: #e5e5e5;@input-border-color-focus: #edad5d;@txt-color-1: #ffffff;@txt-color-2: #000000;@txt-color-3: #858585;@txt-color-4: #ebb23d;@txt-color-5: #666666;@txt-color-6: #737373;@txt-color-7: #888888;@txt-color-8: #999;@txt-color-9: #aaa;@txt-color-10: #e79235;@txt-color-11: #777777;@txt-color-12: #333;@txt-color-13: #EBAD3C;@txt-color-ok: #38c00e;@txt-color-14: #040162;@txt-color-15: #555555;@txt-color-warning: #e51125;@primary-gradient-color-2: #ebb23d;@gradient-1: linear-gradient(90deg, @primary-color 0%, @primary-gradient-color-2 100%);@carousel-background: #f3f3f3;@carousel-dot-border: #666666;@input-txt-color: #595F6E;@input-label-color: #aaaaaa;@component-title-color: #EBAD3C;@steps-inactive-background: #CCCCCC;@steps-active-background: #EBAD3C;@divider-color: #ffffff;@switch-button-disable: #888888;@switch-background-disable: #ccc;@title-divider-color: #e5e5e5;@notice-background: #eeeeee;@close-icon-color: #888;@border-color: #333;@border-color-2: #EBAD3C;@border-color-3: #cccccc;@border-color-4: #dddddd;@border-color-5: #f0f0f0;@border-color-6: #ffffff;@border-color-7: #bbbbbb;@border-color-8: #000000;@border-color-9: #e79235;@border-color-10: #d9d9d9;